import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 公共路由
export const constantRoutes = [
    // {
    //     path: '/redirect',
    //     component: Layout,
    //     hidden: true,
    //     children: [
    //         {
    //             path: '/redirect/:path(.*)',
    //             component: () => import('@/views/redirect')
    //         }
    //     ]
    // },
    {
        path: '/',
        component: () => import('@/views/Index'),
        hidden: true
    },
    {
        path: '/home',
        component: () => import('@/views/Index'),
        hidden: true
    },
    {
        path: '/login',
        component: () => import('@/views/login'),
        hidden: true
    },
    {
        path: '/msg',
        component: () => import('@/views/msg'),
        hidden: true
    },
    {
        path: '/source',
        component: () => import('@/views/source'),
        hidden: true
    },
    {
        path: '/gaikuang',
        component: () => import('@/views/gaikuang'),
        hidden: true
    },
    {
        path: '/shoufang',
        component: () => import('@/views/shoufang'),
        hidden: true
    },
    {
        path: '/diyu',
        component: () => import('@/views/diyu'),
        hidden: true
    },
    {
        path: '/my',
        component: () => import('@/views/my'),
        hidden: true
    },
    {
        path: '/chat',
        component: () => import('@/views/chat'),
        hidden: true
    },
    {
        path: '/my/edit',
        component: () => import('@/views/my-inp'),
        hidden: true
    },
]
export default new Router({
    mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})
