import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from 'element-ui'
import './assets/styles/element-variables.scss'
Vue.config.productionTip = false
import Cookies from "js-cookie";
import store from './store'
import './assets/icons' // icon
Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})
import './permission' // permission control
import vant from 'vant';
import 'vant/lib/index.css';
Vue.use(vant);
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from "@/utils/ruoyi";
// 字典数据组件
import DictData from '@/components/DictData'
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
import { getDicts } from "@/api/dict";
DictData.install()
Vue.prototype.getDicts = getDicts
import echarts from "echarts";
Vue.prototype.$echarts = echarts;

Vue.prototype.parseTime = parseTime;
new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
