import request from '@/utils/request'

// 登录方法
export function login(username, password, code, uuid,openid) {
    const data = {
        username,
        password,
        code,
        uuid
    }
    if(openid){
        data.openid=openid;
        return request({
            url: '/system/wechat/user/login',
            headers: {
                isToken: false
            },
            method: 'post',
            data: data
        })

    }
    return request({
        url: '/login',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}

// 注册方法
export function register(data) {
    return request({
        url: '/register',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}

// 获取用户详细信息
export function getInfo() {
    return request({
        url: '/getInfo',
        method: 'get'
    })
}

// 退出方法
export function logout() {
    return request({
        url: '/logout',
        method: 'post'
    })
}
// 退出登录
export function logoutwx(data) {
    return request({
        url: '/logoutClearAssociation',
        method: 'post',
        data:data
    })
}


// 获取验证码
export function getCodeImg() {
    return request({
        url: '/captchaImage',
        headers: {
            isToken: false
        },
        method: 'get',
        timeout: 20000
    })
}
