const getters = {
    token: state => state.user.token,
    languageType:state=>state.user.languageType,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    nickName:state => state.user.nickName,
    user_type:state=>state.user.user_type,
    webdata:state=>state.user.webdata,
    deptId:state=>state.user.deptId,
}
export default getters
