import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import request, { isRelogin } from '@/utils/request'
import {getWechatCode, isWXBrowser} from "@/utils";
import {Toast} from "vant";
import {getInfo} from "@/api/login";

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/auth-redirect', '/bind', '/register','/ce','/chat']

router.beforeEach((to, from, next) => {
    NProgress.start()
    if (getToken()) {
        //企业用户
        if(store.getters.user_type==='10'){
            if(to.path!=='/home' && !store.getters.languageType){
                next({path:'/home'})
                NProgress.done()
            }
        }else{
            if(to.path==='/home'){
                next({ path: '/' })
                NProgress.done()
            }
        }

        to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
        /* has token*/
        if (to.path === '/login') {
            next({ path: '/' })
            NProgress.done()
        } else {
            store.dispatch('GetInfo').then(() => {
                isRelogin.show = false
                next()
            }).catch(err => {
                // if (isWXBrowser) {
                //     Toast.loading({
                //         message: '自动登录中...',
                //         forbidClick: true,
                //     });
                //     getWechatCode((code)=>{
                //         request({ url:'/system/wechat/user/getWebPageAccessToken/'+code, method: 'get',}).then(res=>{
                //             if(res.sysWechatUser){
                //                 this.openid=res.sysWechatUser.openid
                //                 Toast.clear()
                //                 Toast(err);
                //                 next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
                //                 return;
                //             }
                //             store.dispatch("wxLogin", res.token)
                //             localStorage.setItem("languageType",'')
                //             getInfo().then((res) => {
                //                 next({path:  "/"})
                //                 Toast.clear()
                //             })
                //         })
                //     });
                //     return;
                // }
                store.dispatch('LogOut').then(() => {
                    // Message.error(err)
                    Toast(err);
                    next({ path: '/' })
                })
            })
            // next()
        }
    } else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            // if (isWXBrowser) {
            //     Toast.loading({
            //         message: '自动登录中...',
            //         forbidClick: true,
            //     });
            //     getWechatCode((code)=>{
            //         request({ url:'/system/wechat/user/getWebPageAccessToken/'+code, method: 'get',}).then(res=>{
            //             if(res.sysWechatUser){
            //                 this.openid=res.sysWechatUser.openid
            //                 Toast.clear()
            //                 next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
            //                 NProgress.done()
            //                 return;
            //             }
            //             store
            //                 .dispatch("wxLogin", res.token)
            //             localStorage.setItem("languageType",'')
            //             getInfo().then((res) => {
            //                 next({path:  "/"})
            //                 Toast.clear()
            //                 NProgress.done()
            //             })
            //         })
            //     });
            //
            //     return;
            // }
            next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})
